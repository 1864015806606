import React from 'react';
import PropTypes from 'prop-types'
import InnerHTML from 'html-react-parser'
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// install Swiper modules
SwiperCore.use([ Autoplay, Navigation, Pagination, Scrollbar, A11y ]);


const SwiperSlider = (props) => {
    return (
        <>
        <Swiper
            spaceBetween={props.spaceBetween}
            loop= {true}
            autoplay={{ delay: props.slideDeley }}
            slidesPerView={props.slidesPerView}
            pagination={false}
            scrollbar={false}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log('slide change')}
            >
            {
            props.slides.map((slide)=>(
                <SwiperSlide>
                    <div className="testimonial_header">
                        <div className="testimonial_image">
                            {slide.img &&
                                <img src={slide.img} alt="" />
                            }
                        </div>
                        <div className="testimonial_cite">
                            {slide.cite &&
                                InnerHTML(slide.cite)
                            }
                        </div>
                    </div>
                    {slide.content &&
                        InnerHTML(slide.content)
                    }
                </SwiperSlide>
            ))
            }
        </Swiper>
            
        </>
    )
}

SwiperSlider.propTypes = {
    slides: PropTypes.array
}

export default SwiperSlider